@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply focus:outline-none focus-within:outline-none active:outline-none target:outline-none focus-visible:outline-none;
  }

  input {
    @apply focus:outline-none;
  }

  button,
  [type='radio'],
  [type='checkbox'] {
    @apply cursor-pointer;
  }

  button:disabled {
    @apply opacity-40 cursor-default;
  }

  button:disabled svg {
    @apply cursor-default;
  }

  input,
  textarea,
  button,
  select,
  a,
  details,
  summary,
  svg {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  /* Safari select appearance */
  select {
    -webkit-appearance: none;
  }

  details > summary::-webkit-details-marker {
    @apply hidden;
  }

  /* Safari legend bug workaround */
  /* https://stackoverflow.com/questions/29967730/how-to-get-cross-browser-form-fieldset-content-height-in-with-legend */
  legend {
    float: left;
    margin-top: -1rem;
  }
  legend + * {
    clear: both;
  }
}

@layer components {
  /* Accordion */
  .arrow-center {
    @apply absolute w-full -bottom-3 z-10 bg-white;
  }

  /* Page container */
  .page-container {
    @apply relative mx-auto pb-16 min-h-screen flex flex-col;
  }

  /* Empty */
  .empty-data-text {
    @apply text-gray-400 text-sm;
  }

  /* Attachments */
  .links {
    @apply flex flex-col gap-y-4 w-full;
  }

  .links-item {
    @apply flex items-center justify-between text-sm underline text-gray-600;
  }

  .links-item:not(:last-child) {
    @apply border-b pb-4;
  }

  /* Contracts */
  .contracts > div:last-child {
    @apply pb-0;
  }

  /* Dialog select */
  .dialog-select {
    @apply border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-visible:outline-offset-0 focus-within:outline-2 focus-within:outline focus-within:outline-primary cursor-pointer;
  }

  .disabled,
  .dialog-select--disabled {
    @apply opacity-40 focus-within:outline-none focus-within:border-gray-300 cursor-default pointer-events-none;
  }

  .dialog-select__label {
    @apply block text-xs font-medium text-gray-900;
  }

  .dialog-select__input {
    @apply flex justify-between w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus-within:outline-none text-sm;
  }

  /* Sort dialog */
  .sort {
    @apply grid grid-cols-[repeat(2,_minmax(min-content_,auto))] xs:grid-cols-[repeat(3,_minmax(min-content_,auto))] gap-4 p-5;
  }

  .sort-item {
    @apply flex items-center justify-between border border-gray-200 rounded-md py-2 px-4 text-center text-gray-500 whitespace-nowrap;
  }

  /* hot link */
  .hot-link {
    @apply truncate font-medium tracking-wide text-gray-900 hover:underline max-w-xs focus:underline focus-visible:underline underline-offset-1;
  }

  /* Table cards */
  .card {
    @apply p-4 border-0 border-t;
  }

  .card:first-child {
    @apply border-b-0;
  }

  .card-header {
    @apply flex flex-col flex-1 gap-y-0.5 w-full;
  }

  .card-header__primary-text {
    @apply flex justify-between text-sm text-gray-600;
  }

  .card-header__primary-text > div {
    @apply self-baseline;
  }

  .card-header__secondary-text {
    @apply flex items-center gap-x-2 text-sm text-gray-500;
  }

  /* Mobile table data */
  .data {
    @apply mt-2 border-t;
  }

  .data-item {
    @apply flex justify-between text-sm rounded-md px-4 py-1 mt-2 gap-2;
  }

  .data-item:nth-child(odd) {
    @apply bg-gray-100;
  }

  .data-item.data-item--action {
    @apply border-t pt-2 bg-transparent;
  }

  .data-item__title {
    @apply text-gray-500;
  }

  .data-item__data {
    @apply flex items-center text-blue font-medium truncate;
  }

  /* Sort in table */
  .sort-column {
    @apply flex flex-row items-center gap-2 whitespace-nowrap;
  }

  .sort-column__icons {
    @apply flex flex-row items-center;
  }

  /* Icons */
  .icon {
    @apply text-gray-500 w-auto;
  }

  .icon-xxs {
    @apply icon h-3;
  }

  .icon-xs {
    @apply icon h-4;
  }

  .icon-sm {
    @apply icon h-5;
  }

  .icon-md {
    @apply icon h-8;
  }

  .icon-lg {
    @apply icon h-10;
  }

  /* Main title */
  .main-title {
    @apply text-3xl font-extrabold text-gray-900 pt-4 sm:pt-8;
  }

  /* Buttons */
  .icon-button {
    @apply flex items-center justify-center px-2 py-1.5 z-30 border text-sm font-medium rounded-md focus-within:outline focus-within:outline-offset-2 focus-within:outline-primary cursor-pointer;
  }

  .button {
    @apply border px-3 py-1.5 text-sm text-center font-medium rounded-md cursor-pointer transition-colors duration-150;
  }

  .button--without-bg {
    @apply bg-white rounded-md font-medium text-primary hover:text-primary-darker focus-within:outline focus-within:outline-offset-2 focus-within:outline-primary;
  }

  /* Badges */
  .badge--green {
    @apply bg-green-100 text-green-800;
  }

  .badge--red {
    @apply bg-red-100 text-red-800;
  }

  .badge--gray {
    @apply bg-gray-100 text-gray-800;
  }

  /* Sections */
  .section-title {
    @apply text-lg leading-6 font-medium text-gray-900;
  }

  .section-label {
    @apply text-sm font-medium text-gray-500;
  }

  /* Menu items */
  .menu-item {
    @apply pl-4 py-2 px-3 flex items-center text-sm font-medium border-transparent text-gray-600;
  }

  .menu-item:not(.menu-item--active):hover {
    @apply hover:text-gray-900 hover:bg-gray-50;
  }

  .menu-item--active {
    @apply bg-primary/10 border-primary text-primary;
  }

  .menu-item__icon {
    @apply mr-3 flex-shrink-0 h-6 w-6 text-gray-400;
  }

  .menu-item.menu-item--active .menu-item__icon {
    @apply text-primary;
  }

  .menu-item:not(.menu-item--active):hover .menu-item__icon {
    @apply text-gray-500;
  }

  /* Tabs */
  .tab-bar__nav-item {
    @apply whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm border-transparent text-gray-500;
  }

  .tab-bar__nav-item:not(.tab-bar__nav-item--active):hover {
    @apply hover:border-gray-300 hover:text-gray-700;
  }

  .tab-bar__nav-item--active {
    @apply border-primary text-primary;
  }

  /* Widget */
  .dashboard-widget-icon {
    @apply h-8 xs:h-12 text-inherit;
  }

  .widget-container__icon-wrap-icon {
    @apply icon-md text-white hover:text-white cursor-default;
  }

  /* Error */
  .full-page-error {
    @apply h-screen flex flex-col items-center justify-center gap-y-4;
  }

  .error-text {
    @apply text-red-800;
  }

  /* Data line */
  .data-line {
    @apply mt-6 grid grid-cols-1 sm:grid-cols-2;
  }

  .data-line__item {
    @apply flex flex-row justify-between py-5 border-t;
  }

  .data-line__item:nth-child(odd) {
    @apply sm:border-r sm:pr-4;
  }

  .data-line__item:nth-child(even) {
    @apply sm:pl-4;
  }

  .data-line__item-data {
    @apply text-sm text-gray-900;
  }

  /* Icons hover */
  .icon-without-hover {
    @apply text-white hover:text-white;
  }

  /* Table */
  .table-header {
    @apply flex flex-col xs:flex-row xs:items-center gap-y-4 justify-between bg-white pb-0 pt-8 w-full mb-2;
  }

  .table-header__subtitle {
    @apply text-lg leading-6 font-medium text-gray-900;
  }

  .data-table__body {
    @apply min-h-[44px] xs:grid-flow-row-dense;
  }

  .data-table__body-row {
    @apply w-full grid gap-4 px-4 hover:bg-gray-50 text-gray-500;
  }

  .data-table__body-row > div,
  .data-table__body-row > label {
    @apply flex flex-col py-3 w-full text-sm whitespace-nowrap items-start justify-center truncate;
  }

  .data-table__body-row span {
    @apply truncate w-full;
  }

  .data-table__body-row > .exception-row {
    @apply flex flex-row justify-between max-w-full gap-2;
  }

  .data-table__body-row > .action-1 {
    @apply flex flex-row p-0 gap-2 w-14 items-center justify-end ml-auto pr-2;
  }

  .data-table__body-row > .action-2 {
    @apply flex flex-row flex-nowrap whitespace-nowrap gap-2 w-14 items-center justify-center justify-self-end;
  }

  /* Paginataion */
  .pagination-item {
    @apply w-10 h-full border-b-2 border-transparent hover:border-gray-300 text-gray-400 hover:text-gray-700 flex items-center justify-center text-sm font-medium;
  }

  .pagination-item--arrow {
    @apply text-gray-500;
  }

  .pagination-item:disabled {
    @apply hover:bg-transparent border-solid hover:border-transparent;
  }

  /* Link */
  .secondary-download-link {
    @apply text-sm underline text-gray-600 hover:text-gray-800;
  }

  /* Radio */
  .radio-group {
    @apply border-gray-200 first:rounded-tl-md first:rounded-tr-md last:rounded-br-md last:rounded-bl-md checked:border-primary/30 z-0 relative border p-4 flex cursor-pointer focus:outline-none;
  }
}

@layer utilities {
  .custom-ring {
    @apply focus:ring-2 focus:ring-offset-2 focus:ring-primary focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary focus-within:ring-2 focus-within:ring-primary;
  }

  /* Text sizes */
  .text-md {
    font-size: 16px;
  }

  .sm-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}

.button--primary {
  @apply border-transparent text-white bg-primary hover:bg-primary-darker;
}

.button--secondary {
  @apply bg-white border-gray-300 text-gray-700 hover:bg-gray-50;
}

.button--tertiary {
  @apply border-transparent text-primary hover:text-primary-darker focus:outline-transparent focus-within:outline-transparent;
}

/* Tables responsive */
.cell-last {
  @apply flex justify-end w-14 shrink-0 grow-0;
}
.cell-last > div {
  @apply w-fit;
}

/* Dashboard table */
.dashboard-table .search {
  @apply border-b-0;
}

/* Hotels details monthly overview table */
.hotels-monthly-overview-table .employee div:last-child {
  @apply font-medium text-gray-500;
}

.partners-client-list-table .data-table-header,
.partners-client-details-table .data-table-header {
  @apply hidden twsm:block;
}

.partners-client-list-table .data-table__body,
.partners-client-details-table .data-table__body {
  @apply grid-cols-1 twsm:block;
}

.overbooked-dashboard-table .data-table-header,
.expired-dashboard-table .data-table-header,
.dashboard-table .data-table-header,
.projects-exceptions-table .data-table-header,
.projects-active-employees-table .data-table-header,
.projects-history-employees-table .data-table-header,
.hotels-monthly-overview-table .data-table-header,
.hotels-list-table .data-table-header {
  @apply hidden twmd:block;
}

.overbooked-dashboard-table .data-table__body,
.expired-dashboard-table .data-table__body,
.dashboard-table .data-table__body .projects-exceptions-table .data-table__body,
.projects-active-employees-table .data-table__body,
.projects-history-employees-table .data-table__body,
.hotels-monthly-overview-table .data-table__body,
.hotels-list-table .data-table__body {
  @apply grid-cols-1 twmd:block;
}

.employees-list-table .data-table-header,
.employees-details-table .data-table-header,
.users-list-table .data-table-header,
.partners-host-list-table .data-table-header,
.projects-list-table .data-table-header {
  @apply hidden twlg:block;
}

.employees-list-table .data-table__body,
.employees-details-table .data-table__body,
.users-list-table .data-table__body,
.partners-host-list-table .data-table__body,
.projects-list-table .data-table__body {
  @apply grid-cols-1 twlg:block;
}

.partners-host-details-table .data-table-header,
.moving-out-table .data-table-header {
  @apply hidden twxl:block;
}

.partners-host-details-table .data-table__body,
.moving-out-table .data-table__body {
  @apply grid-cols-1 twxl:block;
}

.projects-active-employees-table .employee > div:last-child,
.projects-history-employees-table .employee > div:last-child {
  @apply font-medium text-gray-500;
}

.employees-list-table .employee div:last-child {
  @apply font-medium text-gray-500;
}

.employees-list-table .employee div:last-child {
  @apply font-medium text-gray-500;
}

/* Riports current table */
.riports-current-table > div {
  @apply top-0;
}

.riports-current-table {
  @apply min-w-fit;
}

.riports-statistics-table-container {
  @apply min-w-[1440px];
}

.riports-usage-table-container {
  @apply min-w-[1920px];
}

/* Moving in missing employees table  */
.missing-employees-table {
  @apply mt-4;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Custom scrollbar */
.riports-table-container::-webkit-scrollbar-thumb,
.riports-table-container::-webkit-scrollbar {
  @apply appearance-none w-1 h-1 transform-gpu transition-all duration-500 rounded bg-primary-darker/90 hover:bg-primary-darker;
}

.riports-table-container::-webkit-scrollbar-track {
  @apply bg-white;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Circular progress */
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

.mdc-circular-progress {
  animation: rotate 2s linear infinite;
}

.mdc-circular-progress .mdc-circular-progress__path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

#notification {
  @apply overflow-x-hidden;
}

.checkbox-cell {
  overflow: visible !important;
}

@keyframes bouncingAnimation {
  0% {
    transform: translateY(0);
  }

  33% {
    transform: translateX(-0.25em);
  }

  66% {
    transform: translateX(0.25em);
  }

  100% {
    transform: translateY(0);
  }
}

/* Animations */

.bouncingAnimation {
  animation: bouncingAnimation 0.1s 2 normal cubic-bezier(0.17, 0.67, 0.83, 0.67) forwards;
}
